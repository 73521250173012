$info: #981430;
$pagination-current-background-color: $info;
$pagination-current-border-color: $info;

// Import Main CSS file from theme
@import "main";

.hero {
    .title,
    .subtitle {
        text-shadow: 1px 1px rgba($primary, 0.5);
        animation: appear 1s;
    }
}

div.highlight {
    margin-bottom: 1rem;
}

@keyframes appear {
    0% {
        opacity: 0;
        transform: translateY(1rem);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

#carbonads {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  }

  #carbonads {
    display: block;
    overflow: hidden;
    max-width: 100%;
    position: relative;
    background-color: hsl(0, 0%, 99%);
    border: solid 1px #eee;
    font-size: 22px;
    box-sizing: content-box;
    margin-bottom: 1rem;
  }

  #carbonads > span {
    display: block;
  }

  #carbonads a {
    color: inherit;
    text-decoration: none;
  }

  #carbonads a:hover {
    color: inherit;
  }

  .carbon-wrap {
    display: flex;
    align-items: center;
  }

  .carbon-img {
    display: block;
    margin: 0;
    line-height: 1;
  }

  .carbon-img img {
    display: block;
    height: 90px;
    width: auto;
  }

  .carbon-text {
    display: block;
    padding: 0 1em;
    line-height: 1.35;
    text-align: left;
  }

  .carbon-poweredby {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 6px 10px;
    background: repeating-linear-gradient(
                    -45deg,
                    transparent,
                    transparent 5px,
                    hsla(0, 0%, 0%, 0.025) 5px,
                    hsla(0, 0%, 0%, 0.025) 10px
    )
    hsla(203, 11%, 95%, 0.8);
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 600;
    font-size: 8px;
    border-top-left-radius: 4px;
    line-height: 1;
  }

  @media only screen and (min-width: 320px) and (max-width: 759px) {
    .carbon-text {
      font-size: 14px;
    }
  }
