$fa-font-path: "../fonts/font-awesome";

$primary: #36A9E1;

@import "vendor/font-awesome/font-awesome.scss";
// @import "vendor/octicons/octicons";
@import "variables";
// @import "highlight";

// @import "asides/github";
@import "vendor/bulma/bulma.sass";

// html {
//   min-height: 100%;

//   body {
//     margin-top: $navbar-height;
//     margin-bottom: ($navbar-height + ($navbar-height / 3));

//     .jumbotron {
//       margin-top: -20px !important;
//       padding-top: 0;
//       padding-bottom: 0;

//       .container {
//         @media (min-width: 600px) {
//           background-image: url('/assets/images/logo_pint.png');
//           background-position: right center;
//           background-repeat: no-repeat;
//           background-origin: padding-box;
//           padding-top: 3em !important;
//           padding-bottom: 5em !important;
//         }
//       }
//     }

//     .blog-index {
//       article {
//         margin-bottom: 3em;
//       }
//     }

//     aside {
//       #hide-meta-aside {
//         border: 0 !important;

//         i {
//           vertical-align: middle;
//         }
//       }
//     }

//     #subnav #show-meta-aside-wrapper {
//       @include pull-right();
//       text-align: right;

//       &:focus,
//       &:hover,
//       a {
//         border: 0 !important;
//         background: none;
//       }

//       a {
//         i {
//           vertical-align: middle;
//         }
//       }
//     }

//     .event-meta {
//       .label {
//         margin: 0.25em;
//       }
//     }

    @media(min-width: 600px) {
      #pint-pubs-chart {
        width: 100%;
      }
    }

    @media(min-width: 900px) {
      #pint-pubs-chart {
        width: 700px;
      }
    }

//     .ref-label {
//       width: 100%;
//       display: block !important;
//       font-size: 85% !important;
//     }

//     .post {
//       article {
//         @include clearfix;

//         p {
//           text-align: justify;
//         }

//         img.groups-logo, img.projects-logo {
//           max-width: 75%;
//           max-height: 4em;
//           margin-right: 1em;
//         }
//       }
//       article.on-index {
//         padding-bottom: 1em;
//         border-bottom: 1px solid $page-header-border-color;
//         margin-top: 1em !important;
//         margin-bottom: 1em !important;

//         header {
//           border-bottom: none !important;  // overriding bootstrap
//           padding-bottom: 0 !important;  // overriding bootstrap
//           margin: 0 !important;  // overriding bootstrap

//           h1.entry-title {
//             margin-top: 0;
//           }
//         }
//       }
//       footer, footer#contribute-footer {
//         @include clearfix;
//         margin-top: 1em;
//         padding: 1em;
//       }
//     }

//     .post.post-code, .post.post-group, .post.post-project, .post.post-event {
//       section.group-meta, section.event-meta {
//         .logo {
//           img {
//             @include img-responsive;
//             width: 100%;
//           }
//         }
//         address {
//           margin-bottom: 0;
//         }
//       }
//       section.github-widget {
//         > .gothub-box-download {
//           min-height: none !important;
//         }
//       }
//     }

//     footer#site-footer {
//       .seperator {
//         padding-left: 0.5em;
//         padding-right: 0.5em;
//       }
//     }
//   }
// }



.event-meta-row {
    display: flex;
    justify-content: space-between;
    gap: 4px;
    padding: 8px 8px 8px 8px;
}

.event-meta-piece {
    display: flex;
    flex-direction: column;
}

.panel-container {
    display: grid;
    justify-content: space-between;
    row-gap: 16px;

    @media only screen and (min-width: 0px) {
    justify-content: center;
    column-gap: 0px;
        grid-template-columns: 80vw;
        font-size: smaller;
    }

    @media only screen and (min-width: 600px) {
        column-gap: 16px;
        grid-template-columns: repeat(2, 40vw);
        font-size: small;
    }

    @media only screen and (min-width: 900px) {
        column-gap: 16px;
        grid-template-columns: repeat(3, 25vw);
        font-size: medium;
    }
}

.panel-card {
    padding: 8px 8px 8px 8px;
    width: 100%;
    height: 100%;
}

.modal-card-head-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.stats-buttons {
    display: flex;
    gap: 4px;
}

.bibtex-entry-container .btn-group-xs {
    display: inline-block;
}

.content .bibliography li + li {
    margin-top: 2rem;
}

.navbar-brand {
    font-weight: 700;
}